<template>
	<div class="card">
		<div class="card-header">
			<div>
				<h4 class="card-header-title">Rides by Staff</h4>

				<small class="text-muted me-3">Rides by staff over the last 30 days</small>
			</div>
		</div>
		<div class="card-body">
			<bar-chart v-if="ridesByUsersLast30Days" class="chart barchart" :chart-data="ridesByUsersLast30Days"
				:options="barChartOptions"></bar-chart>
			<div v-if="loadingUsersChartData" class="d-flex justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { getDailyBarChartData } from '@/utils/chart'
import BarChart from '@/components/charts/BarChart'
import { axiosInstance } from '@/plugins/axios'
import {ref, defineProps} from 'vue'

const props = defineProps({
  companyId: {
    type: [Number, String],
    default: null,
    required: true
  }
})

    const loadingUsersChartData = ref(true)
    const errorLoadingUsersChartData = ref(false)
    const ridesByUsersLast30Days = ref(null)
    const barChartOptions = ref({
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false
            },
            offset: true
          }
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [8, 4],
              color: '#F6F6F7'
            },
            ticks: {
              stepSize: 2
            }
          }
        ]
      }
    })


 const fetchStaffChartsData = () => {
    loadingUsersChartData.value = true
    errorLoadingUsersChartData.value = false

   axiosInstance
      .get(`/v1/corporates/${props.companyId}/staff/graph`)
      .then((res) => {
        const data = res.data.map((r) => {
          r.month = new Date(r.created_at).getMonth() + 1
          return r
        })
        ridesByUsersLast30Days.value = getDailyBarChartData(data)
      })
      .catch(() => {
        errorLoadingUsersChartData.value = true
      })
      .finally(() => (loadingUsersChartData.value = false))
  }

fetchStaffChartsData()
</script>

<style scoped></style>
